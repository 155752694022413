
import { Injectable } from '@angular/core';
import { AdminMemberService } from './admin-member.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// Interfaces
import { User } from '../../../core/user';

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';
import { Note } from 'app/interfaces/global.interface';

@Injectable()
export class AdminMemberFirebaseService implements AdminMemberService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }

    // async setUserDetails(data: Data): Promise<void> {
    //     const collection = this.afDB.collection('users')
    //     return this.dBContextFirebaseService.set(data, collection)
    // }

    async getMemberNotes(uid: string): Promise<Note[]> {
        const collection = this.afDB.firestore.collection('users').doc(uid).collection('notes')
        return await this.dBContextFirebaseService.getCollection(collection)
    }


}
