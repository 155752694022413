import { Injectable } from '@angular/core';
import { UserService } from './custom-user.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { User } from '../../core/user';
import { resolve } from 'url';


@Injectable()
export class CustomUserFirebaseService implements UserService {

    constructor(
        private afAuth: AngularFireAuth,
        public afDB: AngularFirestore
    ) {
    }

    async getUserMembers(uid: any): Promise<User[]> {

      //  headerRow: ['Last Name', 'First Name', 'ID Number', 'Email', 'Actions'],

        const pat: User = {
            lastName: 'du Preez',
            firstName: 'Ruan',
            idNumber: '545788 5468 36654 324',
            email: 'ruandupr33z@gmail.com',
            uid: '458875265',
            isActive: true
        }
        const pat2: User = {
            lastName: 'Travolta',
            firstName: 'John',
            idNumber: '2344 2341212 324',
            email: 'JohnnyT@gmail.com',
            uid: '123124',
            isActive: true

        }
        const member: User[] = [
            pat,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
            pat2,
        ];

        const resusdflt1 = await this.afDB.firestore
            .collection('users')
            .doc(uid)
            .get()
            .then(doc => {

                // if (doc.data() !== undefined) {
                //     this.user.firstName = doc.data().firstName
                //     this.user.lastName = doc.data().lastName
                // }
            }).catch((error) => console.log(error))


        return member
    }
}
