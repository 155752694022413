
import { Injectable } from '@angular/core';
import { MaintenanceService } from './maintenance.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// services
import { DBContextFirebaseService } from '../db-context/db-context.firebase.service';
import { User } from '../../core/user';
import { resolve } from 'q';

@Injectable()
export class MaintenanceFirebaseService implements MaintenanceService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }

    async setUserDetails(data: Data): Promise<any> {
        const collection = this.afDB.collection('users')
        return this.dBContextFirebaseService.set(data, collection)
    }

    async getUserDetails(uid: string): Promise<User> {
        const returnObject = await this.afDB.firestore.collection('users').doc(uid).get()
        return returnObject.data()
    }

    async doesUserExist(email: string): Promise<boolean> {
        const collection = await this.afDB.firestore.collection('users')
        const query = collection.where('email', '==', email)
        return await this.dBContextFirebaseService.getCollection(query).then((users) => {

            if (!users || users.length <= 0) {
                return false
            }
            return true
        })
    }

    async getAllUsersDetails(userType?: string): Promise<User[]> {

        const collection = this.afDB.firestore.collection('users')

        if (userType !== '' && userType !== undefined && userType != null) {
            const query = collection.where('userType', '==', userType)
            return await this.dBContextFirebaseService.getCollection(query)
        } else {
            return await this.dBContextFirebaseService.getCollection(collection)
        }
    }

    async getAdvisorLinkedDoctors(uid?: string): Promise<User[]> {
        const collection = this.afDB.firestore.collection('advisorLinkedDoctors')
            .where('advisorUid', '==', uid)
        return await this.dBContextFirebaseService.getCollection(collection)
    }

    async setAdvisorLinkedDoctor(data: User): Promise<any> {
        const collection = this.afDB.collection('advisorLinkedDoctors')
        return this.dBContextFirebaseService.set(data, collection)
    }

    async removeUser(uid: string): Promise<boolean> {
        return this.afDB.collection('users')
            .doc(uid)
            .delete()
            .then(() => {
                return true
            }).catch(() => {
                return false
            })
    }
}
