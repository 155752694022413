
export let MemberLevel = {
  bronze: { variable: 'bronze', display: 'Bronze' },
  silver: { variable: 'silver', display: 'Silver' },
  gold: { variable: 'gold', display: 'Gold' },
}

export let MedicalCategory = {
  glycaemia: { variable: 'glycaemia', display: 'Glycaemia' }, // Blood Glucose
  hypertension: { variable: 'hypertension', display: 'Hypertension' }, // Blood Pressure
  dyslipidemia: { variable: 'dyslipidemia', display: 'Dyslipidemia' }, // Lipid Fats
  other: { variable: 'other', display: 'Other' }  // other
}

export let DollarEvent = {
  hba1cLogged: { variable: 'hba1cLogged', display: 'Hba1c Logged' },
  glycaemiaLogged: { variable: 'glycaemiaLogged', display: 'Glycaemia Logged' },
  cholesterolLogged: { variable: 'cholesterolLogged', display: 'Cholesterol Logged' },
  ldlLogged: { variable: 'ldlLogged', display: 'LDL Logged' },
  hdlLogged: { variable: 'hdlLogged', display: 'HDL Logged' },
  triglyceridesLogged: { variable: 'triglyceridesLogged', display: 'Triglycerides Logged' },
  weightLogged: { variable: 'weightLogged', display: 'weight Logged' },
  waistCircLogged: { variable: 'waistCircLogged', display: 'waist Circ. Logged' },
  doctorConsultLogged: { variable: 'doctorConsultLogged', display: 'Doctor Consult Logged' },
  educatorConsultLogged: { variable: 'educatorConsultLogged', display: 'Educator Consult Logged' },
  dieticianConsultLogged: { variable: 'dieticianConsultLogged', display: 'Dietician Consult Logged' },
  footScreeningLogged: { variable: 'footScreeningLogged', display: 'Foot Screening Logged' },
  eyeScreeningLogged: { variable: 'eyeScreeningLogged', display: 'Eye Screening Logged' },
  hba1cTestLogged: { variable: 'hba1cTestLogged', display: 'Hba1c Test Logged' },
  lipogramTestLogged: { variable: 'lipogramTestLogged', display: 'Lipogram Test Logged' },
  mauTestLogged: { variable: 'mauTestLogged', display: 'MAU Test Logged' },
  learningItemCompleted: { variable: 'learningItemCompleted', display: 'Learning Item Completed' },
  activitySessionLogged: { variable: 'activitySessionLogged', display: 'Activity Session Logged' },
  stepsLogged: { variable: 'stepsLogged', display: 'Steps Logged' },
  glucoseReadingLogged: { variable: 'glucoseReadingLogged', display: 'Glucose Reading Logged' },
}

export let UserRoles = {
  viewDashboard:
  {
    variable: 'viewDashboard', display: 'View Dashboard', description: 'View the Main Dashboard'
    , portal: 'admin'
  },
  manageRolesAndUsers:
  {
    variable: 'manageRolesAndUsers', display: 'Roles & Users', description: 'Add and Edit Roles & Users'
    , portal: 'admin'
  },
  manageMembers:
  {
    variable: 'manageMembers', display: 'Member Management', description: 'Member Management'
    , portal: 'admin'
  },
  viewAuditTrail:
  {
    variable: 'viewAuditTrail', display: 'View Audit Trail', description: 'View Audit Trail'
    , portal: 'admin'
  },
  manageLanguageLibrary:
  {
    variable: 'manageLanguageLibrary', display: 'Manage Language Library', description: 'Manage Language Library'
    , portal: 'admin'
  },
  manageServiceAgreement:
  {
    variable: 'manageServiceAgreement', display: 'Manage Service Agreement', description: 'Manage Service Agreement'
    , portal: 'admin'
  },
  manageBillingChannels:
  {
    variable: 'manageBillingChannels', display: 'Manage Billing Channels', description: 'Manage Billing Channels'
    , portal: 'admin'
  },
  manageClientInformation:
  {
    variable: 'manageClientInformation', display: 'Manage Client Information', description: 'Manage Client Information'
    , portal: 'admin'
  },
  manageDollarEarnings:
  {
    variable: 'manageDollarEarnings', display: 'Manage Dollar Earnings', description: 'Manage Dollar Earnings'
    , portal: 'admin'
  },
  manageClientImages:
  {
    variable: 'manageClientImages', display: 'Manage ClientImages', description: 'Manage ClientImages'
    , portal: 'admin'
  },
  manageMedications:
  {
    variable: 'manageMedications', display: 'Manage Medications', description: 'Manage Medications'
    , portal: 'admin'
  },
  manageRewards:
  {
    variable: 'manageRewards', display: 'Manage Rewards', description: 'Manage Rewards'
    , portal: 'admin'
  },
  manageProgram:
  {
    variable: 'manageProgram', display: 'Manage Program', description: 'Manage Program'
    , portal: 'admin'
  },
  manageFitnessDevices:
  {
    variable: 'manageFitnessDevices', display: 'Manage Fitness Devices', description: 'Manage Fitness Devices'
    , portal: 'admin'
  },
  manageGlucoseMeters:
  {
    variable: 'manageGlucoseMeters', display: 'Manage Glucose Meters', description: 'Manage Glucose Meters'
    , portal: 'admin'
  },
  manageLoyaltyClubs:
  {
    variable: 'manageLoyaltyClubs', display: 'Manage Loyalty Clubs', description: 'Manage Loyalty Clubs'
    , portal: 'admin'
  },
  manageSupplyCompanies:
  {
    variable: 'manageSupplyCompanies', display: 'Manage Supply Companies', description: 'Manage Supply Companies'
    , portal: 'admin'
  },
  manageAdviceRequest:
  {
    variable: 'manageAdviceRequest', display: 'Manage Advice Request', description: 'Manage Advice Request'
    , portal: 'admin'
  },
  manageIssues:
  {
    variable: 'manageIssues', display: 'Manage Issues', description: 'Manage Issues'
    , portal: 'admin'
  },
  manageNews:
  {
    variable: 'manageNews', display: 'Manage News', description: 'Manage News'
    , portal: 'admin'
  },
  manageLearnTopics:
  {
    variable: 'manageLearnTopics', display: 'Manage Learn Topics', description: 'Manage Learn Topics'
    , portal: 'admin'
  },
  addLearnTopic:
  {
    variable: 'addLearnTopic', display: 'Add LearnTopic', description: 'Add LearnTopic'
    , portal: 'admin'
  },
  manageRewardFulfillment:
  {
    variable: 'manageRewardFulfillment', display: 'Manage Reward Fulfillment', description: 'Manage Reward Fulfillment'
    , portal: 'admin'
  },
  manageMemberCoaching:
  {
    variable: 'manageMemberCoaching', display: 'Manage Member Coaching', description: 'Manage Member Coaching'
    , portal: 'admin'
  },
  viewReports:
  {
    variable: 'viewReports', display: 'View Reports', description: 'Can View all Reports'
    , portal: 'admin'
  },

  // user doctor portal
  user_manageMorbidities:
  {
    variable: 'user_manageMorbidities', display: 'Manage Morbidities', description: 'Manage Morbidities'
    , portal: 'user'
  },

  user_manageMetrics:
  {
    variable: 'user_manageMetrics', display: 'Manage Metrics', description: 'Manage Metrics'
    , portal: 'user'
  },

  user_manageCarePlan:
  {
    variable: 'user_manageCarePlan', display: 'Manage Care Plan', description: 'Manage Care Plan'
    , portal: 'user'
  },

  user_managePrescription:
  {
    variable: 'user_managePrescription', display: 'Manage Prescription', description: 'Manage Prescription'
    , portal: 'user'
  },

  user_manageActivity:
  {
    variable: 'user_manageActivity', display: 'ManageActivity', description: 'Manage Activity'
    , portal: 'user'
  },

  user_manageNotes:
  {
    variable: 'user_manageNotes', display: 'Manage Notes', description: 'Manage Notes'
    , portal: 'user'
  },

  user_manageAdviceRequest:
  {
    variable: 'user_manageAdviceRequest', display: 'Manage Advice Request', description: 'Manage Advice Request'
    , portal: 'user'
  },

  user_managePatientChat:
  {
    variable: 'user_managePatientChat', display: 'Manage Patient Chat', description: 'Manage Patient Chat'
    , portal: 'user'
  },

  user_manageEventLogs:
  {
    variable: 'user_manageEventLogs', display: 'Manage Event Logs', description: 'Manage Event Logs'
    , portal: 'user'
  },

  user_managePatientProfile:
  {
    variable: 'user_managePatientProfile', display: 'Manage Patient Profile', description: 'Manage Patient Profile'
    , portal: 'user'
  },

  user_viewStatusReport:
  {
    variable: 'user_viewStatusReport', display: 'View Status Report', description: 'View Status Report'
    , portal: 'user'
  }
}
