import { Injectable } from '@angular/core';
import { DBContextService } from './db-context.service';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Notifications, IconEnum, FromEnum, AlignEnum, NotificationTypeEnum } from '../../providers/notifications';
import * as firebase from 'firebase'

@Injectable()
export class DBContextFirebaseService implements DBContextService {

    constructor(
        public afDB: AngularFirestore,
        public n: Notifications,
    ) { }

    async getCollection(query: firebase.firestore.Query): Promise<any[]> {

        try {
            const querySnapshot = await query.get()
            let returnList = []
            querySnapshot.forEach(doc => {
                returnList = returnList.concat(doc.data())
            });
            return returnList
        } catch (error) {
            this.n.show('Error: Check Console', FromEnum.top, AlignEnum.center, IconEnum.notifications, NotificationTypeEnum.danger)
            console.log(error)
        }
    }

    async get(docRef: firebase.firestore.DocumentData): Promise<any> {

        try {
            const returnObject = await docRef.get()
            return returnObject.data()
        } catch (error) {
            this.n.show('Error: Check Console', FromEnum.top, AlignEnum.center, IconEnum.notifications, NotificationTypeEnum.danger)
            console.log(error)
        }
    }

    async set(data, collection: AngularFirestoreCollection): Promise<any> {

        try {
            data.dateUpdated = firebase.firestore.Timestamp.now()
            if (!data.uid || data.uid === '') {
                data.uid = this.afDB.createId()
            }
            return await collection
                .doc(data.uid)
                .set(data, { merge: true })
                .then(() => {
                    return data;
                })
        } catch (error) {
            this.n.show('Error: Check Console', FromEnum.top, AlignEnum.center, IconEnum.notifications, NotificationTypeEnum.danger)
            console.log(error)
        }
    }
}
