import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service'
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class AuthGuardViewDashboard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.viewDashboard(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          this.router.navigate(['/admin/pgs/login'])
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUsersAndRoles implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageUsersAndRoles(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}


@Injectable()
export class AuthGuardManageMembers implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageMembers(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardViewAuditTrail implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.viewAuditTrail(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageLanguageLibrary implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageLanguageLibrary(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageServiceAgreement implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageServiceAgreement(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageBillingChannels implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageBillingChannels(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageClientInformation implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageClientInformation(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageDollarEarnings implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageDollarEarnings(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageClientImages implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageClientImages(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageMedications implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageMedications(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageRewards implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageRewards(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageProgram implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageProgram(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageFitnessDevices implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageFitnessDevices(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageGlucoseMeters implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageGlucoseMeters(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageLoyaltyClubs implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageLoyaltyClubs(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageSupplyCompanies implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageSupplyCompanies(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageAdviceRequest implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageAdviceRequest(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}



@Injectable()
export class AuthGuardManageIssues implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageIssues(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardManageNews implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageNews(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
@Injectable()
export class AuthGuardManageLearnTopics implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageLearnTopics(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
@Injectable()
export class AuthGuardAddLearnTopic implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.addLearnTopic(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
@Injectable()
export class AuthGuardManageRewardFulfillment implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageRewardFulfillment(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
@Injectable()
export class AuthGuardManageMemberCoaching implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.manageMemberCoaching(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardViewReports implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.viewReports(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
