import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';

// import { AdminSidebarModule } from './admin-sidebar/admin-sidebar.module';
// import { UserSidebarModule } from './user-sidebar/user-sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
// import { UserNavbarModule } from './user-navbar/user-navbar.module';
// import { AdminNavbarModule } from './admin-navbar/admin-navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
// import { UserMainLayoutComponent } from './user-layouts/main/user-main-layout.component';
// import { AdminMainLayoutComponent } from './admin-layouts/main/admin-main-layout.component';
import { AppRoutes } from './app.routing';

// My imports
import { CoreModule } from './core/core.module';
import { Notifications, IconEnum, FromEnum, AlignEnum, NotificationTypeEnum } from './providers/notifications';

import { Globals } from './globals';
import { OldShitGlobalsToRemove } from './oldshitglobalstoremove';

import { ComponentsModule } from './components/components.module';

// Services
import { UserGeneralFirebaseService } from './services/user/general/user-general-firebase.service';
import { CustomUserFirebaseService } from './services/custom-user/custom-user.firebase.service';
import { MaintenanceFirebaseService } from './services/maintenance/maintenance.firebase.service';
import { AdminMemberFirebaseService } from './services/admin/admin-member/admin-member.firebase.service';
import { DBContextFirebaseService } from './services/db-context/db-context.firebase.service';
import { LinkedEntityFirebaseService } from './services/admin/linked-entity/linked-entity.firebase.service';
import { GeneralFirebaseService } from './services/admin/general/general.firebase.service';
import { OperationsFirebaseService } from './services/admin/operations/operations.firebase.service';
import { ClientSetupFirebaseService } from './services/admin/client-setup/client-setup.firebase.service';
import { GlobalFirebaseService } from './services/global/global.firebase.service';

// Member service
import { MemberFirebaseService } from './services/member/member.firebase.service';


// REFACTOR THIS CRAP!
import { PatientCarePlan } from './firebase-functions/patient-careplan';
import { PatientManagement } from './firebase-functions/patient-management';
import { PatientRewards } from './firebase-functions/patient-rewards';

// Providers
import { DateFormatting } from './providers/date-formatting';
import { NumberFormatting } from './providers/number-formatting';
import { ObjectTransformations } from './providers/object-transformations';
import { TablesDotNet } from './providers/tables-dot-net';
import { DatePipe } from '@angular/common';
import { CustomPushNotify } from './providers/push.notifications';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpModule,
    MaterialModule,
    MatNativeDateModule,
    // UserSidebarModule,
    // AdminSidebarModule,
    // UserNavbarModule,
    // AdminNavbarModule,
    FooterModule,
    FixedpluginModule,
    CoreModule,
    ComponentsModule,
    HttpClientModule
  ],
  declarations: [
    AppComponent,
    // UserMainLayoutComponent,
  ],
  providers: [
    Notifications,
    DateFormatting,
    NumberFormatting,
    ObjectTransformations,
    Globals,
    OldShitGlobalsToRemove,
    UserGeneralFirebaseService,
    CustomUserFirebaseService,
    MaintenanceFirebaseService,
    DBContextFirebaseService,
    TablesDotNet,
    DatePipe,
    PatientCarePlan,
    PatientManagement,
    PatientRewards,
    LinkedEntityFirebaseService,
    MemberFirebaseService,
    AdminMemberFirebaseService,
    GeneralFirebaseService,
    OperationsFirebaseService,
    ClientSetupFirebaseService,
    GlobalFirebaseService,
    CustomPushNotify

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
