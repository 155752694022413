// globals.ts
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

declare var $: any;

@Injectable()
export class OldShitGlobalsToRemove {
  loading = true;
  loggedInDoctorToken;
  selectedPatientToken;
  firstName = ''
  lastName = ''
  doctorFirstName = ''
  doctorLastName = ''
  gender = ''
  myDateOfBirth = ''
  age = 0
  idNumber = ''
  medicalInsurer = ''
  membership = ''
  metrics = {
    'HbA1c': 'HbA1c',
    'Cholesterol': 'Cholesterol',
    'MAUARC': 'MAUARC',
    'HDL': 'HDL',
    'LDL': 'LDL',
    'Triglycerides': 'Triglycerides',
    'WaistCircumference': 'WaistCircumference',
    'WeightBmi': 'WeightBmi',
    'Bloodpressure': 'Bloodpressure',
    'PlasmaGlucose': 'PlasmaGlucose',
    'PlasmaGlucoseReadings': 'PlasmaGlucoseReadings',
    'TestingPlan': 'TestingPlan',
    'GlucoseTarget': 'GlucoseTarget',

  }
  otherOptions = {
    'moveHistory': 'moveHistory',
    'morbidities': 'morbidities',
    'diabetesComplications': 'diabetesComplications',
    'carePlan': 'carePlan',
    'medication': 'medication',
    'general': 'general',
    'event': 'event',
    'rewardsHistory': 'rewardsHistory',
    'manage': 'manage',
  }
  medication = {
    'bloodGlucose': 'bloodGlucose',
    'bloodPressure': 'bloodPressure',
    'bloodFats': 'bloodFats',
    'other': 'other',
  }

  eventTypes = {
    'servicing': 'servicing',
    'clinical': 'clinical',
  }

  manage = {
    'HbA1cTest': 'HbA1cTest',
    'MAUACRTest': 'MAUACRTest',
    'cholesterolLipogramTest': 'cholesterolLipogramTest',
    'diabetesEducatorConsultation': 'diabetesEducatorConsultation',
    'dieticianConsultation': 'dieticianConsultation',
    'doctorConsultation': 'doctorConsultation',
    'eyeScreening': 'eyeScreening',
    'footScreening': 'footScreening',
    'albuminCrceatinineRatio': 'albuminCrceatinineRatio',
  }

  rewards = {
    'HbA1c': 100,
    'Bloodpressure': 50,
    'Cholesterol': 25,
    'LDL': 25,
    'HDL': 25,
    'Triglycerides': 25,
    'WeightBmi': 25,
    'WaistCircumference': 40,
    'doesDoctor': 40,
    'doesEducator': 40,
    'doesDietician': 50,
    'doesfootScreening': 50,
    'doeseyeScreening': 50,
    'doeshba1c': 10,
    'doesLipogram': 20,
    'doesMau': 30,
  }

  rewardsName = {
    'HbA1c': 'HbA1c',
    'Bloodpressure': 'Blood Pressure',
    'Cholesterol': 'Cholesterol ',
    'LDL': 'LDL',
    'HDL': 'HDL',
    'Triglycerides': 'Triglycerides',
    'WeightBmi': 'Weight',
    'WaistCircumference': 'Waist Circumference',
    'doesDoctor': 'Doctor Consultation',
    'doesEducator': 'Diabetes Educator Consultation',
    'doesDietician': 'Dietician Consultation',
    'doesfootScreening': 'Foot Screening',
    'doeseyeScreening': 'Eye Screening',
    'doeshba1c': 'HbA1c Test',
    'doesLipogram': 'Cholesterol / Lipogram Test',
    'doesMau': 'Kidney Function Test',
  }

  constructor(
    public datePipe: DatePipe,
    private afAuth: AngularFireAuth,
    public router: Router,
    public afDB: AngularFirestore
  ) {
    this.selectedPatientToken = ''
    this.loggedInDoctorToken = ''
  }

  setLoggedInDoctorToken(uid: string) {
    this.loggedInDoctorToken = uid
    this.afDB.firestore
      .collection('users')
      .doc(uid)
      .get()
      .then(doc => {
        console.log(doc.data())
        if (doc.data() !== undefined) {
          this.doctorFirstName = doc.data().firstName
          this.doctorLastName = doc.data().lastName
        }
      }).catch((error) => console.log(error))
  }

  getLoggedInDoctorToken() {
    return this.loggedInDoctorToken;
  }

  getLoggedInDoctorDisplayNameToken() {
    return (this.doctorFirstName + ' ' + this.doctorLastName);
  }

  setSelectedPatientToken(val: string) {
    this.selectedPatientToken = val
  }

  getSelectedPatientToken() {
    return this.selectedPatientToken;
  }

  getSelectedPatientMembership() {
    return this.membership;
  }

  setSelectedPatientDetails(userDetails) {
    this.firstName = userDetails['firstName']
    this.lastName = userDetails['lastName']
    this.gender = userDetails['gender']
    this.myDateOfBirth = userDetails['myDateOfBirth']
    this.age = userDetails['age']
    this.idNumber = userDetails['idNumber']
    this.medicalInsurer = userDetails['medicalInsurer']
    this.membership = userDetails['membership']
  }

  getSelectedPatientDetails() {
    const patientDetails = {
      'firstName': this.firstName,
      'lastName': this.lastName,
      'gender': this.gender,
      'myDateOfBirth': this.myDateOfBirth,
      'age': this.age,
      'idNumber': this.idNumber,
      'medicalInsurer': this.medicalInsurer,
      'membership': this.membership,
    }
    return patientDetails;
  }

  // Date Time
  formatDateSF(date) {
    if (date === '' || date === undefined || date === null) {
      return '0'
    }

    const month_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const month_index = date.getMonth();
    const year = date.getFullYear();

    return '' + day + '-' + month_names[month_index] + '-' + year;
    // return this.datePipe.transform(date, 'yyyy-MM-dd')
  }

  formatDateToUnix(date) {
    if (date === '' || date === undefined || date === null) {
      return '0'
    }
    const unixDate = new Date(date).getTime() / 1000
    return unixDate
  }

  formatUnixToDate(unix) {
    if (unix === '' || unix === undefined || unix === null) {
      return '0'
    }
    const formattedDate = new Date(unix * 1000)
    return this.formatDateSF(formattedDate)
  }

  currentDateSF() {
    return this.formatDateSF(new Date())
  }

  yearsAndMonthDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years <= 0) {
      return (months + ' Months')
    }
    return (years + ' Years and ' + (months - (years * 12)) + ' Months')
  }

  yearsDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years < 0) {
      return (years * -1)
    }
    return years
  }

  currentAge(date) {
    if (date === '' || date === undefined || date === null) {
      return 0
    }

    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  DaysDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);

    return days;
  }

  // Notifications
  showNotification(displayMessage, from, align, iconName) {
    $.notify({
      icon: iconName,
      message: displayMessage

      // TODO THE ICON IS HARD CODED

    }, {
        type: 'primary',
        timer: 300,
        placement: {
          from: from,
          align: align
        },
        // tslint:disable-next-line:max-line-length
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          // tslint:disable-next-line:max-line-length
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '</div>'
      });
  }

  InitialDoctorCheck(): boolean {

    // Check Logged in Doctor Token
    if (this.getLoggedInDoctorToken() === '') {
      this.afAuth.auth.onAuthStateChanged(user => {
        if (!user) {
          this.router.navigate(['/login']);
          return false;
        } else {
          this.setLoggedInDoctorToken(user.uid)
          return true;
        }
      })
    }

    // Check selected patient token
    if (this.getSelectedPatientToken() === '') {
      this.router.navigate(['/user-selection']);
      return false;
    }
    return true;
  }

  // Validations
  RoundDecimal(decimals, value) {
    const valueString = value.toString().replace(',', '.');

    value = parseFloat(valueString);
    if (decimals !== 0) {
      value = Math.round(value * (decimals * 10)) / (decimals * 10);
    } else {
      value = Math.round(value);
    }
    return value;
  }

  validationMinMax(min, max, value, valueName) {

    if (value === null || value === undefined) {
      return valueName + ' - No Value Entered';
    }
    if (value.toString() === '' || value.toString() === ' ') {
      return valueName + ' - No Value Entered';
    }

    if ((value.toString().match(new RegExp('\\.', 'g')) !== null) && (value.toString().match(new RegExp('\\.', 'g')).length > 1)) {
      return valueName + ' is an Invalid Number';
    }

    const valueString = value.toString().replace(',', '.');
    if (!(!isNaN(parseFloat(valueString)) && isFinite(valueString))) {
      return valueName + ' is an Invalid Number';
    }

    if (value > max) {
      return valueName + ' must be Less than or Equal to ' + max;
    }
    if (value < min) {
      return valueName + ' must be More than or Equal to ' + min;
    }
    return '';
  }

  validationDate(date) {

    if (date === null || date === undefined || date === '') {
      return 'No Date Selected';
    }

    try {
      const curDate = new Date();

      if (new Date(date) > new Date(curDate)) {
        return 'Cannot be Future Date';
      }

    } catch (error) {
      return 'Not a Valid Date';

    }

    return '';
  }


  // this should move
  addStandardAppNotification(uid, message) {
    const key = this.afDB.createId();
    const saveData = {
      date: Date(),
      message: message
    }
    this.afDB.collection('users/' + uid + '/notifications').doc(key).set(saveData, { merge: true })
      .then(data => {

        console.log('notification added')
      })
      .catch(function (error) {
        // this.loading.dismiss();
        // console.error('Error writing document: ', error);
      });
  }

}
