import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

declare var $: any;

@Injectable()
export class NumberFormatting {

  constructor(
    public datePipe: DatePipe,
  ) {}

  RoundDecimal(decimals, value) {
    const valueString = value.toString().replace(',', '.');

    value = parseFloat(valueString);
    if (decimals !== 0) {
      value = Math.round(value * (decimals * 10)) / (decimals * 10);
    } else {
      value = Math.round(value);
    }
    return value;
  }
}
