
// globals.ts
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';

import { User } from '../app/core/user';
import { LanguageLibrary } from './interfaces/admin/language.interface';
import { DBContextFirebaseService } from './services/db-context/db-context.firebase.service';

declare var $: any;

@Injectable()
export class Globals {
  loading = true;
  selectedMemberToken;
  firstName = ''
  lastName = ''
  userFirstName = ''
  userLastName = ''
  gender = ''
  myDateOfBirth = ''
  age = 0
  idNumber = ''
  medicalInsurer = ''
  membership = ''

  constructor(
    private afAuth: AngularFireAuth,
    public afDB: AngularFirestore,
    private dBContextFirebaseService: DBContextFirebaseService
  ) {
  }

  getLoggedInUser(): User {
    const selectedMember = localStorage.getItem('loggedInUser');
    return <User>(JSON.parse(selectedMember));
  }

  setLoggedInUserDetails(loggedInUser: User) {
    localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
  }

  async setSelectedMember(selectedMember: User) {

    if (!selectedMember.language || selectedMember.language === '') {
      selectedMember.language = 'english'
    }

    // Get the selected members Notifications Library.
    const collection = this.afDB.firestore
      .collection('languageLibrary')
      .orderBy('page', 'desc')
      .where('language', '==', selectedMember.language)

    const returnList: LanguageLibrary[] = await this.dBContextFirebaseService.getCollection(collection)

    selectedMember.languageLibrary = {};

    returnList.forEach(element => {
      selectedMember.languageLibrary[element.variable] = element.translation
    });

    localStorage.setItem('selectedMember', JSON.stringify(selectedMember));
  }

  getSelectedMember(): User {
    const selectedMember = localStorage.getItem('selectedMember');
    return <User>(JSON.parse(selectedMember));
  }

  getLoggedInUserDisplayName() {
    const loggedInUser = this.getLoggedInUser()
    return (loggedInUser.firstName + ' ' + loggedInUser.lastName);
  }


  // ------------- THIS KAK BELOW MUST BE MOVED! ----------------

  // Validations
  RoundDecimal(decimals, value) {
    const valueString = value.toString().replace(',', '.');

    value = parseFloat(valueString);
    if (decimals !== 0) {
      value = Math.round(value * (decimals * 10)) / (decimals * 10);
    } else {
      value = Math.round(value);
    }
    return value;
  }

  validationMinMax(min, max, value, valueName) {

    if (value === null || value === undefined) {
      return valueName + ' - No Value Entered';
    }
    if (value.toString() === '' || value.toString() === ' ') {
      return valueName + ' - No Value Entered';
    }

    if ((value.toString().match(new RegExp('\\.', 'g')) !== null) && (value.toString().match(new RegExp('\\.', 'g')).length > 1)) {
      return valueName + ' is an Invalid Number';
    }

    const valueString = value.toString().replace(',', '.');
    if (!(!isNaN(parseFloat(valueString)) && isFinite(valueString))) {
      return valueName + ' is an Invalid Number';
    }

    if (value > max) {
      return valueName + ' must be Less than or Equal to ' + max;
    }
    if (value < min) {
      return valueName + ' must be More than or Equal to ' + min;
    }
    return '';
  }

  validationDate(date) {

    if (date === null || date === undefined || date === '') {
      return 'No Date Selected';
    }

    try {
      const curDate = new Date();

      if (new Date(date) > new Date(curDate)) {
        return 'Cannot be Future Date';
      }

    } catch (error) {
      return 'Not a Valid Date';

    }

    return '';
  }


  // this should move
  addStandardAppNotification(uid, message) {
    const key = this.afDB.createId();
    const saveData = {
      date: Date(),
      message: message
    }
    this.afDB.collection('users/' + uid + '/notifications').doc(key).set(saveData, { merge: true })
      .then(data => {

        console.log('notification added')
      })
      .catch(function (error) {
        // this.loading.dismiss();
        // console.error('Error writing document: ', error);
      });
  }


  currentAge(date) {
    if (date === '' || date === undefined || date === null) {
      return 0
    }

    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // ------------- THIS KAK MUST BE REMOVED! ----------------

}
