import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { OldShitGlobalsToRemove } from '../../app/oldshitglobalstoremove';

@Injectable()

// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------


export class PatientManagement {

    constructor(
        public router: Router,
        private afAuth: AngularFireAuth,
        public afDB: AngularFirestore,
        public g: OldShitGlobalsToRemove
    ) { }

    GetAllPatients() {
        const promise = new Promise((resolve, reject) => {
            this.afDB.firestore
                .collection('users')
                .get()
                .then(data => {
                    let returnList = []
                    data.docs.forEach(element => {
                        returnList = returnList.concat([element.data()])
                    });
                    resolve(returnList);
                }).catch((error) => reject(error))
        })

        return promise;
    }

    GetDoctorPatients(doctorId) {
        const promise = new Promise((resolve, reject) => {
            this.afDB.firestore
                .collection('users')
                .where('doctorId', '==', doctorId)
                .get()
                .then(data => {
                    let returnList = []
                    data.docs.forEach(element => {
                        returnList = returnList.concat([element.data()])

                    });
                    resolve(returnList);
                }).catch((error) => reject(error))
        })
        return promise;
    }

    GetCurrentlyLoggedInDoctorPatients() {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.onAuthStateChanged(user => {
                this.afDB.firestore
                    .collection('users')
                    .where('doctorId', '==', user.uid)
                    .orderBy('lastName', 'asc')
                    .get()
                    .then(data => {
                        let returnList = []
                        data.docs.forEach(element => {

                            const list = element.data();
                            list.age = this.g.currentAge(list.dateOfBirth);

                            returnList = returnList.concat([list])

                        });
                        resolve(returnList);
                    }).catch((error) => reject(error))
            })
        })
    }

}
