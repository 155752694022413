import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Injectable()
export class CustomPushNotify {

    constructor(
        private http: HttpClient
    ) { }

    pushMessage(title: string, text: string, specificUseruid?: string) {

        let getUrl = ''

        console.log(title)
        console.log(specificUseruid)

        if (specificUseruid) {
            getUrl = 'https://us-central1-dianet-web-angular-6-a9850.cloudfunctions.net/pushToUser?messageTitle='
                + title + '&uid=' + specificUseruid;
        } else {
            getUrl = 'https://us-central1-dianet-web-angular-6-a9850.cloudfunctions.net/pushToAll?messageTitle=' + title;
        }
        this.http.post(getUrl, text).subscribe(
            doc => {
                console.log('Notify Success: ', doc);
            },
            error => {
                console.log('Notify Fail: ', error);
            }
        )
    }

}
