
import { Injectable } from '@angular/core';
import { MemberService } from './member.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// services
import { DBContextFirebaseService } from '../db-context/db-context.firebase.service';


import { MedicalCategory } from '../../enums/global.enums'
import { User } from 'app/core/user';
import { Chat } from 'app/interfaces/global.interface';


@Injectable()
export class MemberFirebaseService implements MemberService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService
    ) { }

    // Post Login
    async getUserMembers(loggedInUser: User): Promise<User[]> {

        const collection = this.afDB.firestore.collection('users')

        let query;
        if (loggedInUser.userType === 'doctor') {
            query = collection.where('doctorId', '==', loggedInUser.uid)
        } else
            if (loggedInUser.userType === 'educator') {
                query = collection.where('educatorUid', '==', loggedInUser.uid)
            }
        return this.dBContextFirebaseService.getCollection(query)
    }


    // Chat
    async setChatMessage(data: Chat): Promise<Chat> {
        const collection = this.afDB.collection('users/' + data.memberUid + '/chat')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // async getMedicationDetails(uid: string): Promise<Medication> {
    //     const docRef: firebase.firestore.DocumentData = this.afDB.firestore.collection('medication').doc(uid)
    //     return await this.dBContextFirebaseService.get(docRef)
    // }

    // async getAllTypeMedications(medicalCategory?: string): Promise<Medication[]> {

    //     const collection = this.afDB.firestore.collection('medication')
    //     if (medicalCategory) {
    //         const collectionWhere = collection.where('type', '==', MedicalCategory[medicalCategory])
    //         return await this.dBContextFirebaseService.getCollection(collectionWhere)
    //     } else {
    //         return await this.dBContextFirebaseService.getCollection(collection)
    //     }
    // }
}
