import { Injectable } from '@angular/core';

declare var $: any;
export enum NotificationTypeEnum {
  info = 1,
  success,
  warning,
  danger,
  primary,
}

export enum AlignEnum {
  left = 1,
  right,
  center
}

export enum FromEnum {
  top = 1,
  bottom
}


export enum IconEnum {
  notifications = 1,
}

@Injectable()
export class Notifications {

  constructor(
  ) {
  }

  // Notifications
  show(message, from: FromEnum, align: AlignEnum, icon: IconEnum, type: NotificationTypeEnum) {

    $.notify({
      icon: IconEnum[icon],
      message: message
    }, {
        type: NotificationTypeEnum[type],
        timer: 1000,
        placement: {
          from: FromEnum[from],
          align: AlignEnum[align]
        },
        template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
          // tslint:disable-next-line:max-line-length
          '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          // tslint:disable-next-line:max-line-length
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
}
