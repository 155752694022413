import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { OldShitGlobalsToRemove } from '../../app/oldshitglobalstoremove';
import { Globals } from '../../app/globals';
import { CustomPushNotify } from 'app/providers/push.notifications';

@Injectable()

// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------

export class PatientRewards {

    balance = 0
    constructor(
        public router: Router,
        public afDB: AngularFirestore,
        public g: OldShitGlobalsToRemove,
        public pushNotify: CustomPushNotify,
        public gb: Globals
    ) { }

    LogEventReward(name: string) {

        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid
            const self = this

            this.ValidateEventReward(name, patientId)


        })
    }

    LogMetricReward(name: string, readingOne: string, readingDate: string, readingTwo?: string) {
        this.g.showNotification('Adding', 'top', 'center', 'notifications')

        const displayName = this.g.rewardsName[name] + 'target achieved';

        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid
            const reward = {
                date: new Date(),
                displayName: displayName,
                name: name,
                points: '',
                status: 'Complete',
                type: 'Earned',
            }

            const self = this
            this.GetCurrentPatientTargets()
                .then((targets) => {

                    this.GetCurrentPatientBalance()
                        .then((balance) => {
                            this.ValidateMetricReward(name, patientId, targets, readingOne, readingTwo, balance, readingDate)
                        })
                        .catch((error) => console.log(error))
                })
        })
    }

    SetCurrentPatientPoints(balanceTotal) {
        console.log('SetCurrentPatientPoints')
        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid
            this.afDB.firestore
                .collection('users')
                .doc(patientId)
                .set({ balance: balanceTotal }, { merge: true })
                .then(function () {
                    resolve('Reward Added');
                }).catch((error) => reject(error))
        })
    }

    GetCurrentPatientBalance(): Promise<number> {
        console.log('GetCurrentPatientBalance')
        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid

            // get user Points
            this.afDB.firestore
                .collection('users')
                .doc(patientId)
                .get()
                .then(doc => {
                    resolve(doc.data().balance)
                }).catch((error) => reject(error))
        })
    }

    GetAmountOfRewardsForRollingPeriod(rewardName: string) {
        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid

            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);

            // get user Points
            this.afDB.firestore
                .collection('users')
                .doc(patientId)
                .collection('rewardsHistory')
                .where('name', '==', rewardName)
                .where('date', '>=', date)
                .get()
                .then(querySnapShot => {
                    let returnList = []
                    querySnapShot.forEach(doc => {
                        returnList = returnList.concat(doc.data())
                        console.log(doc.data())
                    });
                    resolve(returnList.length);

                }).catch((error) => reject(error))
        })
    }

    GetCurrentPatientTargets() {
        console.log('GetCurrentPatientTargets')
        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid
            this.afDB.firestore
                .collection('users')
                .doc(patientId)
                .collection('targets')
                .get()
                .then(querySnapShot => {
                    let returnList = []
                    querySnapShot.forEach(doc => {
                        const targetType = doc.data()
                        targetType.name = doc.id
                        returnList = returnList.concat(targetType)
                    });
                    resolve(returnList);

                }).catch((error) => {
                    reject('No Targets Set')
                    console.log(error)
                })
        })
    }


    async SetPatientPoints(name, patientId, message: string) {

        const that = this

        const balance = await this.GetCurrentPatientBalance();

        // this.GetCurrentPatientBalance()
        // .then((balance) => {

        // }).catch((error) => console.log(error))


        const total = (balance + this.g.rewards[name])
        // set user Points
        this.SetCurrentPatientPoints(total)
            .then((results) => {
                this.AddAwardHistory(patientId, name)
                this.g.addStandardAppNotification(patientId, message)
                // Push Notification
                this.pushNotify.pushMessage('Dollars Earned!', message.replace(/<br\s*[\/]?>/gi, '.'), patientId);
            })
            .catch((error) => console.log(error))
    }

    async RefundPatientPoints(patientId, pointsRefunded: number, message) {

        const currentBalance = await this.GetCurrentPatientBalance();
        const refundedBalance = (currentBalance + pointsRefunded)

        this.SetCurrentPatientPoints(refundedBalance)
            .then((results) => {
                this.AddAwardHistory(patientId, name)
                this.g.addStandardAppNotification(patientId, message)
                // Push Notification
                this.pushNotify.pushMessage('Dollars Earned!', message.replace(/<br\s*[\/]?>/gi, '.'), patientId);
            })
            .catch((error) => console.log(error))



    }

    AddAwardHistory(patientId, name) {

        const reward = {
            date: new Date(),
            displayName: this.g.rewardsName[name] + ' target achieved',
            name: name,
            points: 0,
            status: 'Complete',
            type: 'Earned',
        }

        reward.points = this.g.rewards[name];

        const that = this
        this.afDB
            .collection('users')
            .doc(patientId)
            .collection(this.g.otherOptions['rewardsHistory'])
            .doc(this.afDB.createId())
            .set(reward)
            .catch(function (error) {
                that.g.showNotification(error, 'top', 'center', 'notifications')
            });
    }

    ValidateEventReward(name: string, patientId: string) {
        console.log('ValidateReward')
        const membership = this.g.getSelectedPatientMembership()

        this.GetAmountOfRewardsForRollingPeriod(name).then(rolloingAmountResult => {
            switch (name) {
                case 'doesDoctor': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesdoc_ifyouweresillverorgold +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    // patient achieves HbA1c equal to or lower than target
                    if (rolloingAmountResult >= 4) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesdoc_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        // Well done for consulting your Diabetes Doctor! You have earned
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesdoc_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' '
                        + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doesEducator': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesedu_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    if (rolloingAmountResult >= 4) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesedu_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdiabetesedu_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doesDietician': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdieticianif_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    if (rolloingAmountResult >= 1) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdieticianun_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforconsultingyourdieticianyo_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return
                }
                case 'doesfootScreening': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingafootscreeningifyou_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    if (rolloingAmountResult >= 1) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingafootscreeningunfor_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingafootscreeningyouha_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doeseyeScreening': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganeyescreeningifyou_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    // patient achieves HbA1c equal to or lower than target
                    if (rolloingAmountResult >= 1) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganeyescreeningunfor_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganeyescreeningyouha_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doeshba1c': {
                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganhba1ctestifyouwer_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }

                    // patient achieves HbA1c equal to or lower than target
                    if (rolloingAmountResult >= 4) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganhba1ctestunfortun_unfortunatly)
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavinganhba1ctestyouhavee_youhaveearned +
                        ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doesLipogram': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingacholesterollipogra_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }
                    // patient achieves HbA1c equal to or lower than target
                    if (rolloingAmountResult >= 2) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingacholesterollipogra_unfortunatly
                        )
                        return
                    }
                    this.SetPatientPoints(name
                        , patientId
                        // tslint:disable-next-line:max-line-length
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingacholesterollipogra_youhaveearned + ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                case 'doesMau': {

                    if (membership === 'Bronze') {
                        console.log('sending notification : No reward Received')
                        this.g.addStandardAppNotification(patientId,
                            // tslint:disable-next-line:max-line-length
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingakidneyfunctiontest_ifyouweresillverorgold
                            +
                            this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
                        return
                    }
                    // patient achieves HbA1c equal to or lower than target
                    if (rolloingAmountResult >= 1) {
                        this.g.addStandardAppNotification(patientId,
                            this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingakidneyfunctiontest_unfortunatly
                        )
                        return
                    }

                    this.SetPatientPoints(name
                        , patientId
                        // tslint:disable-next-line:max-line-length
                        , this.gb.getSelectedMember().languageLibrary.notifications_welldoneforhavingakidneyfunctiontest_youhaveearned + ' ' +
                        this.g.rewards[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                    )
                    return;
                }
                default: {
                    return;
                }
            }
        })

    }

    DoCalc(name, targets, rolloingAmountResult, rollingLimit, balance, readingOne, patientId, biggerThan, readingDate) {
        const membership = this.g.getSelectedPatientMembership()

        const target = targets.filter(obj => {
            return obj.name === name
        })

        if (this.isObjectnull(target)) {
            this.g.showNotification('No ' + this.g.rewardsName[name] + ' Target has been set for this Patient'
                , 'top', 'center', 'notifications')
            return
        }


        if (biggerThan) {
            if (readingOne > target[0].target) {



                // patient achieves HbA1c equal to or lower than target
                if (membership !== 'Bronze') {
                    this.g.addStandardAppNotification(patientId,
                        this.gb.getSelectedMember().languageLibrary.notifications_your + ' ' + this.g.rewardsName[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_target + ' : ' + target[0].target +
                        '<br/>  ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                        ' ' + readingDate + ': ' + readingOne +
                        '<br/>  ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour + this.g.rewardsName[name] +
                        this.gb.getSelectedMember().languageLibrary.notifications_readingisaboveyourtargetspeaktoyourd_unfortunatly)
                } else {
                    this.g.addStandardAppNotification(patientId,
                        this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' ' +
                        this.gb.getSelectedMember().languageLibrary.notifications_target + ' : ' + target[0].target +
                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                        ':' + readingDate + ': ' + readingOne +

                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour + this.g.rewardsName[name] +
                        this.gb.getSelectedMember().languageLibrary.notifications_readingisaboveyourtargetspeaktoyourd_unfortunatly)

                }
                return;
            }
        } else {
            if (readingOne < target[0].target) {

                if (membership !== 'Bronze') {
                    this.g.addStandardAppNotification(patientId,
                        this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] +
                        ' ' + this.gb.getSelectedMember().languageLibrary.notifications_target +
                        ' : ' + target[0].target +
                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                        ':' + readingDate + ': ' + readingOne +

                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour + this.g.rewardsName[name] +
                        this.gb.getSelectedMember().languageLibrary.notifications_readingisbelowyourtarget +
                        this.gb.getSelectedMember().languageLibrary.notifications_speaktoyourdiabetescareteamabouthowy)

                } else {
                    this.g.addStandardAppNotification(patientId,
                        this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' '
                        + this.gb.getSelectedMember().languageLibrary.notifications_target + ' : ' + target[0].target +
                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                        ':' + readingDate + ': ' + readingOne +

                        '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour + this.g.rewardsName[name] +
                        this.gb.getSelectedMember().languageLibrary.notifications_speaktoyourdiabetescareteamabouthowy)
                }
                return;
            }
        }

        if (membership !== 'Bronze') {
            if (rolloingAmountResult > rollingLimit) {
                this.g.addStandardAppNotification(patientId,
                    this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' ' +
                    this.gb.getSelectedMember().languageLibrary.notifications_target + ' : ' + target[0].target +
                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                    ':' + readingDate + ': ' + readingOne +

                    '<br/>' + this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + ' ' +
                    this.g.rewardsName[name] +
                    this.gb.getSelectedMember().languageLibrary.notifications_target + '! ' +
                    this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyouhavealreadyearnedthe_unfortunatly)
                return
            }

            this.SetPatientPoints(name
                , patientId
                , this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' ' +
                this.gb.getSelectedMember().languageLibrary.notifications_target + ' : ' + target[0].target +
                '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':' +
                readingDate + ': ' + readingOne +

                '<br/>' + this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + ' ' +
                this.g.rewardsName[name] +
                this.gb.getSelectedMember().languageLibrary.notifications_target +
                '!' + ' You have earned ' +
                this.g.rewards[name] + ' ' +
                this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
            )

        } else {
            this.g.addStandardAppNotification(patientId,
                this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] +
                ' ' + + this.gb.getSelectedMember().languageLibrary.notifications_target +
                ' : ' + target[0].target +
                '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':' + readingDate + ': ' + readingOne +

                this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + this.g.rewardsName[name] +
                this.gb.getSelectedMember().languageLibrary.notifications_target +
                this.gb.getSelectedMember().languageLibrary.notifications_ifyouwereasilverorgoldmemberyouwould +
                this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars + '!')
        }

        return;

    }


    ValidateMetricReward(name: string, patientId: string, targets, readingOne, readingTwo, balance, readingDate) {
        console.log('ValidateReward')
        return new Promise((resolve, reject) => {

            const membership = this.g.getSelectedPatientMembership()

            this.GetAmountOfRewardsForRollingPeriod(name).then(rolloingAmountResult => {
                switch (name) {
                    case 'HbA1c': {
                        this.DoCalc(name, targets, rolloingAmountResult, 4, balance, readingOne, patientId, true, readingDate)
                        return
                    }
                    case 'Bloodpressure': {

                        const target = targets.filter(obj => {
                            return obj.name === name
                        })

                        if (this.isObjectnull(target)) {
                            this.g.showNotification('No ' + this.g.rewardsName[name] + ' Target has been set for this Patient'
                                , 'top', 'center', 'notifications')
                            return
                        }

                        if (readingTwo > target[0].targetSystolic || readingOne > target[0].targetDiastolic) {

                            // patient achieves HbA1c equal to or lower than target
                            if (membership !== 'Bronze') {
                                this.g.addStandardAppNotification(patientId,
                                    this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' '
                                    + ' : ' + target[0].targetSystolic + '/' + target[0].targetDiastolic +
                                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon +
                                    ':' + readingDate + ': ' + readingTwo + '/' + readingOne +

                                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour
                                    + this.g.rewardsName[name] +
                                    // tslint:disable-next-line:max-line-length
                                    this.gb.getSelectedMember().languageLibrary.notifications_readingisaboveyourtargetspeaktoyourd_unfortunatly)

                            } else {
                                this.g.addStandardAppNotification(patientId,
                                    this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' ' +
                                    this.gb.getSelectedMember().languageLibrary.notifications_target +
                                    + ' : ' + target[0].targetSystolic + '/' + target[0].targetDiastolic +
                                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':'
                                    + readingDate + ': ' + readingTwo + '/' + readingOne +

                                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyour
                                    + this.g.rewardsName[name] +
                                    // tslint:disable-next-line:max-line-length
                                    this.gb.getSelectedMember().languageLibrary.notifications_readingisaboveyourtargetspeaktoyourd_unfortunatly)

                            }
                            return;
                        }

                        if (membership !== 'Bronze') {
                            if (rolloingAmountResult >= 4) {
                                this.g.addStandardAppNotification(patientId,
                                    this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' '
                                    + this.gb.getSelectedMember().languageLibrary.notifications_target +
                                    ': ' + target[0].targetSystolic + '/' + target[0].targetDiastolic +
                                    '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':'
                                    + readingDate + ': ' + readingTwo + '/' + readingOne +

                                    '<br/>' + this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + ' ' +
                                    this.g.rewardsName[name] +
                                    this.gb.getSelectedMember().languageLibrary.notifications_target + '! ' +
                                    // tslint:disable-next-line:max-line-length
                                    this.gb.getSelectedMember().languageLibrary.notifications_unfortunatelyyouhavealreadyearnedthe_unfortunatly)
                                return
                            }

                            this.SetPatientPoints(name
                                , patientId
                                , this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' '
                                + this.gb.getSelectedMember().languageLibrary.notifications_target +
                                + ': ' + target[0].targetSystolic + '/' + target[0].targetDiastolic +
                                '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':'
                                + readingDate + ': ' + readingTwo + '/' + readingOne +

                                '<br/>' + this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + ' ' +
                                this.g.rewardsName[name] +
                                this.gb.getSelectedMember().languageLibrary.notifications_target +
                                '! ' +
                                this.gb.getSelectedMember().languageLibrary.notifications_youhaveearned +
                                this.g.rewards[name] + ' ' +
                                this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollarskeepupthegoodwork
                            )

                        } else {
                            this.g.addStandardAppNotification(patientId,
                                this.gb.getSelectedMember().languageLibrary.notifications_your + '  ' + this.g.rewardsName[name] + ' '
                                + this.gb.getSelectedMember().languageLibrary.notifications_target +
                                ': ' + target[0].targetSystolic + '/' + target[0].targetDiastolic +
                                '<br/> ' + this.gb.getSelectedMember().languageLibrary.notifications_yourreadingon + ':'
                                + readingDate + ': ' + readingTwo + '/' + readingOne +

                                '<br/>' + this.gb.getSelectedMember().languageLibrary.notifications_welldoneforachievingyour + ' ' +
                                this.g.rewardsName[name] + this.gb.getSelectedMember().languageLibrary.notifications_target + ' ! ' +
                                this.gb.getSelectedMember().languageLibrary.notifications_ifyouwereasilverorgoldmemberyouwould +
                                this.g.rewards[name] + ' ' + this.gb.getSelectedMember().languageLibrary.notifications_diabetesdollars +
                                 '!')
                        }
                        return;
                    }
                    case 'Cholesterol': {
                        this.DoCalc(name, targets, rolloingAmountResult, 2, balance, readingOne, patientId, true, readingDate)
                        break
                    }
                    case 'LDL': {
                        this.DoCalc(name, targets, rolloingAmountResult, 2, balance, readingOne, patientId, true, readingDate)
                        return
                    }
                    case 'HDL': {
                        this.DoCalc(name, targets, rolloingAmountResult, 2, balance, readingOne, patientId, false, readingDate)
                        return
                    }
                    case 'WeightBmi': {
                        this.DoCalc(name, targets, rolloingAmountResult, 4, balance, readingOne, patientId, true, readingDate)
                        return
                    }
                    case 'WaistCircumference': {
                        this.DoCalc(name, targets, rolloingAmountResult, 4, balance, readingOne, patientId, true, readingDate)
                        return
                    }
                    case 'Triglycerides': {
                        this.DoCalc(name, targets, rolloingAmountResult, 2, balance, readingOne, patientId, true, readingDate)
                        return
                    }

                    default: {
                        return;
                    }
                }
            })
        })
    }

    isObjectnull(target): boolean {
        if (target === null || target === undefined) {
            return true
        }
        return false;
    }


}


