import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

declare var $: any;

@Injectable()
export class DateFormatting {

  constructor(
    public datePipe: DatePipe,
  ) { }


  formatDateToStringYYYMMDD(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month };
    if (day.length < 2) { day = '0' + day };

    return [year, month, day].join('-');
  }

  // Date Time
  formatDateToSF(date: Date, addTime?: boolean) {
    if (date === undefined || date === null) {
      return '0'
    }

    const month_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const month_index = date.getMonth();
    const year = date.getFullYear();

    if (addTime) {
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getDate()

      return day + '-' + month_names[month_index] + '-' + year + ' ' + this.formatAMPM(date)
    }
    return '' + day + '-' + month_names[month_index] + '-' + year;
    // return this.datePipe.transform(date, 'yyyy-MM-dd')
  }

  formatStringToSFDate(dateString: any, addTime?: boolean) {
    const date: Date = new Date(dateString)
    if (date === undefined || date === null) {
      return '0'
    }

    const month_names = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const month_index = date.getMonth();
    const year = date.getFullYear();

    if (addTime) {
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getDate()

      return day + '-' + month_names[month_index] + '-' + year + ' ' + this.formatAMPM(date)
    }

    return '' + day + '-' + month_names[month_index] + '-' + year;
    // return this.datePipe.transform(date, 'yyyy-MM-dd')
  }

  formatAMPM(date: Date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const newMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + newMinutes + ' ' + ampm;
    return strTime;
  }

  formatDateToUnix(date) {
    if (date === '' || date === undefined || date === null) {
      return '0'
    }
    const unixDate = new Date(date).getTime() / 1000

    return unixDate
  }

  formatUnixToDate(unix) {
    if (unix === '' || unix === undefined || unix === null) {
      return '0'
    }
    const formattedDate = new Date(unix * 1000)
    return this.formatDateToSF(formattedDate)
  }

  currentDateSF() {
    return this.formatDateToSF(new Date())
  }

  yearsAndMonthDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years <= 0) {
      return (months + ' Months')
    }
    return (years + ' Years and ' + (months - (years * 12)) + ' Months')
  }

  yearsDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years < 0) {
      return (years * -1)
    }
    return years
  }

  DaysDiffTillNow(date) {

    if (date === '' || date === undefined || date === null) {
      return 0
    }
    const actDate = new Date(date)
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);

    return days;
  }
}
