import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'environments/environment';
import {
  AuthGuardUserManageActivity,
  AuthGuardUserManageAdviceRequest,
  AuthGuardUserManageCarePlan,
  AuthGuardUserManageEventLog,
  AuthGuardUserManageManageMetrics,
  AuthGuardUserManageMorbidities,
  AuthGuardUserManageNotes,
  AuthGuardUserManagePatientChat,
  AuthGuardUserManagePatientProfile,
  AuthGuardUserManagePrescription,
  AuthGuardUserViewStatusReport
} from '../core/auth.guard.user';
import {
  AuthGuardViewDashboard,
  AuthGuardUsersAndRoles,
  AuthGuardAddLearnTopic,
  AuthGuardManageAdviceRequest,
  AuthGuardManageBillingChannels,
  AuthGuardManageClientImages,
  AuthGuardManageClientInformation,
  AuthGuardManageDollarEarnings,
  AuthGuardManageFitnessDevices,
  AuthGuardManageGlucoseMeters,
  AuthGuardManageIssues,
  AuthGuardManageLanguageLibrary,
  AuthGuardManageLearnTopics,
  AuthGuardManageServiceAgreement,
  AuthGuardManageLoyaltyClubs,
  AuthGuardManageMedications,
  AuthGuardManageMemberCoaching,
  AuthGuardManageMembers,
  AuthGuardManageNews,
  AuthGuardManageProgram,
  AuthGuardManageRewardFulfillment,
  AuthGuardManageRewards,
  AuthGuardManageSupplyCompanies,
  AuthGuardViewAuditTrail,
  AuthGuardViewReports
} from '../core/auth.guard.admin';

@NgModule({
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
      AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    AuthService,
    AuthGuardUserManageActivity,
    AuthGuardUserManageAdviceRequest,
    AuthGuardUserManageCarePlan,
    AuthGuardUserManageEventLog,
    AuthGuardUserManageManageMetrics,
    AuthGuardUserManageMorbidities,
    AuthGuardUserManageNotes,
    AuthGuardUserManagePatientChat,
    AuthGuardUserManagePatientProfile,
    AuthGuardUserManagePrescription,
    AuthGuardUserViewStatusReport,
    AuthGuardViewDashboard,
    AuthGuardUsersAndRoles,
    AuthGuardAddLearnTopic,
    AuthGuardManageAdviceRequest,
    AuthGuardManageBillingChannels,
    AuthGuardManageClientImages,
    AuthGuardManageClientInformation,
    AuthGuardManageDollarEarnings,
    AuthGuardManageFitnessDevices,
    AuthGuardManageGlucoseMeters,
    AuthGuardManageIssues,
    AuthGuardManageLanguageLibrary,
    AuthGuardManageLearnTopics,
    AuthGuardManageServiceAgreement,
    AuthGuardManageLoyaltyClubs,
    AuthGuardManageMedications,
    AuthGuardManageMemberCoaching,
    AuthGuardManageMembers,
    AuthGuardManageNews,
    AuthGuardManageProgram,
    AuthGuardManageRewardFulfillment,
    AuthGuardManageRewards,
    AuthGuardManageSupplyCompanies,
    AuthGuardViewAuditTrail,
    AuthGuardViewReports
  ]
})
export class CoreModule {

}
