import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service'
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable()
export class AuthGuardUserManageMorbidities implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageMorbidities(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}



@Injectable()
export class AuthGuardUserManageManageMetrics implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageMetrics(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}


@Injectable()
export class AuthGuardUserManageCarePlan implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageCarePlan(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManageActivity implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageActivity(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManageAdviceRequest implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageAdviceRequest(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManageEventLog implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageEventLog(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManageNotes implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_manageNotes(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManagePatientChat implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_managePatientChat(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManagePatientProfile implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_managePatientProfile(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserManagePrescription implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_managePrescription(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}

@Injectable()
export class AuthGuardUserViewStatusReport implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => user && this.auth.user_viewStatusReport(user) ? true : false), // <-- important line
      tap(isAllowed => {
        if (!isAllowed) {
          console.error('Access denied!')
          // IfLoggedIn just show message otherwise route to login
          // this.router.navigate(['/admin/pgs/login'])
          swal({
            title: 'No Access',
            text: 'Request Access from your administrator',
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
      })
    );
  }
}
