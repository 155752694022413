import { Routes } from '@angular/router';

import { AdminMainLayoutComponent } from './admin-layouts/main/admin-main-layout.component';
import { UserMainLayoutComponent } from './user-layouts/main/user-main-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'user/pgs/login',
       // redirectTo: 'admin/pgs/login',
        pathMatch: 'full',
    },
    {
        // user/pgs
        path: 'user',
        children: [{
            path: 'pgs', // pages
            loadChildren: './user-pages/user-pages.module#UserPagesModule'
        }]
    },
    {
        // admin/pgs
        path: 'admin',
        children: [{
            path: 'pgs', // pages
            loadChildren: './admin-pages/admin-pages.module#AdminPagesModule',
        }]
    },
];
