
import { Injectable } from '@angular/core';
import { UserGeneralService } from './user-general.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';
import { ClinicalStrategy } from 'app/interfaces/user/clinical-strategy.interface';
import { PatientIssue } from 'app/interfaces/user/patient-issue.interface';
import {
    DiabetesEducationQuestion,
    DiabetesEducationFaceToFace,
} from 'app/interfaces/user/diabetes-education.interface';
import { Note } from 'app/interfaces/global.interface';

@Injectable()
export class UserGeneralFirebaseService implements UserGeneralService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }

    // Clinical Strategy
    async getLatestClinicalStrategy(userUid: string): Promise<ClinicalStrategy> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('clinicalStrategy')
            .orderBy('dateUpdated', 'desc')
            .limit(1)

        const returnObject = await this.dBContextFirebaseService.getCollection(collection)

        if (returnObject.length <= 0) {
            return null
        } else {
            return returnObject[0]
        }
    }

    async getClinicalStratagies(userUid: string): Promise<ClinicalStrategy[]> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('clinicalStrategy')
            .orderBy('dateUpdated', 'desc')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }

    async setClinicalStrategy(data: ClinicalStrategy, userUid: string): Promise<ClinicalStrategy> {
        const collection = this.afDB.collection('users')
            .doc(userUid)
            .collection('clinicalStrategy')

        return this.dBContextFirebaseService.set(data, collection)
    }

    // Issues
    async getLatestPatientIssues(userUid: string): Promise<PatientIssue> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('patientIssue')
            .orderBy('dateUpdated', 'desc')
            .limit(1)

        const returnObject = await this.dBContextFirebaseService.getCollection(collection)

        if (returnObject.length <= 0) {
            return null
        } else {
            return returnObject[0]
        }
    }

    async getPatientIssues(userUid: string): Promise<PatientIssue[]> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('patientIssue')
            .orderBy('dateUpdated', 'desc')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }

    async setPatientIssue(data: PatientIssue, userUid: string): Promise<PatientIssue> {
        const collection = this.afDB.collection('users')
            .doc(userUid)
            .collection('patientIssue')

        return this.dBContextFirebaseService.set(data, collection)
    }

    // Diabetes Education
    async getDiabetesEducationPatientQuestions(userUid: string): Promise<DiabetesEducationQuestion[]> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('questions')
            .orderBy('completed', 'desc')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }

    // Face to face
    async getDiabetesEducationFaceToFace(userUid: string): Promise<DiabetesEducationFaceToFace[]> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('faceToFaceEducation')
            .orderBy('dateCompleted', 'desc')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }
    async setDiabetesEducationFaceToFace(data: DiabetesEducationFaceToFace, userUid: string): Promise<DiabetesEducationFaceToFace> {
        const collection = this.afDB.collection('users')
            .doc(userUid)
            .collection('faceToFaceEducation')

        return this.dBContextFirebaseService.set(data, collection)
    }

    async getNotes(userUid: string, noteType: string): Promise<Note[]> {
        const collection = this.afDB.firestore.collection('users')
            .doc(userUid)
            .collection('notes')
            .orderBy('dateUpdated', 'desc')
            .where('noteType', '==', noteType)

            return await this.dBContextFirebaseService.getCollection(collection)
    }

    async setNote(data: Note, userUid: string): Promise<Note> {
        const collection = this.afDB.collection('users')
            .doc(userUid)
            .collection('notes')

        return this.dBContextFirebaseService.set(data, collection)
    }
}
