
import { Injectable } from '@angular/core';
import { OperationsService } from './operations.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
// interfaces
import { News, LearnItem, ClaimedReward } from '../../../interfaces/admin/operations.interface'
import * as firebase from 'firebase'

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';
import { resolve } from 'url';
import { Alert } from 'selenium-webdriver';
import { Issue } from '../../../interfaces/admin/client-setup.interface';

@Injectable()
export class OperationsFirebaseService implements OperationsService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }


    // News
    async setNews(data: News): Promise<string> {
        const collection = this.afDB.collection('news')
        return this.dBContextFirebaseService.set(data, collection)
    }
    async getNews(): Promise<News[]> {
        const collection = this.afDB.firestore.collection('news')
        return await this.dBContextFirebaseService.getCollection(collection)
    }

    // Learn Topics
    async setLearnItem(data: LearnItem): Promise<void> {
        const collection = this.afDB.collection('learnItems')
        return this.dBContextFirebaseService.set(data, collection)
    }

    async getLearnItem(uid: string): Promise<LearnItem> {
        const docRef = await this.afDB.firestore.collection('learnItems').doc(uid)
        return this.dBContextFirebaseService.get(docRef)
    }

    async getLearnItems(): Promise<LearnItem[]> {
        const collection = await this.afDB.firestore.collection('learnItems')
        return await this.dBContextFirebaseService.getCollection(collection)
    }

    async getIssues(): Promise<Issue[]> {
        const collection = await this.afDB.firestore.collection('issues')

        const querySnapshot = await collection.get()
        let returnList = []
        querySnapshot.forEach(doc => {
            const mydoc = doc.data()
            mydoc.uid = doc.id
            returnList = returnList.concat(mydoc)
        });
        return returnList
    }
    async setIssue(data: Issue): Promise<Issue> {
        const collection = this.afDB.collection('issues')
        return this.dBContextFirebaseService.set(data, collection)
    }

    async getClaimedRewards(): Promise<ClaimedReward[]> {
        const collection = await this.afDB.firestore.collection('claimedRewards')

        const querySnapshot = await collection.get()
        let returnList = []
        querySnapshot.forEach(doc => {
            const mydoc = doc.data()
            mydoc.uid = doc.id
            returnList = returnList.concat(mydoc)
        });
        return returnList
    }
    async setClaimedReward(data: ClaimedReward): Promise<ClaimedReward> {
        const collection = this.afDB.collection('claimedRewards')
       return this.dBContextFirebaseService.set(data, collection)
            .then(() => {
                const collectionClaimed = this.afDB.collection('users')
                .doc(data.patientUid)
                .collection('rewardsHistory')
                return this.dBContextFirebaseService.set(data, collectionClaimed)
            })
    }
}
