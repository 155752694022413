
import { Injectable } from '@angular/core';
import { LinkedEntityService } from './linked-entity.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// Interfaces
import { FitnessDevice, GlucoseMeter, LoyaltyClub, SupplyCompany } from '../../../interfaces/admin/linked-entity.interface';

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';

@Injectable()
export class LinkedEntityFirebaseService implements LinkedEntityService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }


    // Fitness Devices
    async getAllFitnessDevices(): Promise<FitnessDevice[]> {
        const collection = this.afDB.firestore.collection('fitnessDevices')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)

        return returnList;
    }
    async setFitnessDevice(data: FitnessDevice): Promise<any> {
        const collection = this.afDB.collection('fitnessDevices')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Glucose Meter
    async getAllGlucosemeters(): Promise<GlucoseMeter[]> {
        const collection = this.afDB.firestore.collection('glucoseMeters')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }
    async setGlucosemeter(data: GlucoseMeter): Promise<any> {
        const collection = this.afDB.collection('glucoseMeters')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Loyalty Clubs
    async getAllLoyaltyClubs(): Promise<LoyaltyClub[]> {
        const collection = this.afDB.firestore.collection('loyaltyClubs')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }
    async setLoyaltyClub(data: LoyaltyClub): Promise<any> {
        const collection = this.afDB.collection('loyaltyClubs')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Supply Companies
    async getAllSupplyCompanies(): Promise<SupplyCompany[]> {
        const collection = this.afDB.firestore.collection('supplyCompanies')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }
    async setSupplyCompany(data: SupplyCompany): Promise<any> {
        const collection = this.afDB.collection('supplyCompanies')
        return this.dBContextFirebaseService.set(data, collection)
    }
}
