
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { GlobalService } from '../../services/global/global.service';
import { Advice } from 'app/interfaces/global.interface';
import { DBContextFirebaseService } from '../db-context/db-context.firebase.service';
import { HttpClient } from '@angular/common/http';
import { getUrlScheme } from '@angular/compiler';

// services

// tslint:disable-next-line:max-line-length

@Injectable()
export class GlobalFirebaseService implements GlobalService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService,
        public http: HttpClient

    ) { }
    // --- advice moet hier kom.

    //  Advice
    async setAdvice(data: Advice): Promise<Advice> {
        const collection = this.afDB.collection('advice')
        return this.dBContextFirebaseService.set(data, collection)
    }

    async getAdvice(): Promise<Advice[]> {
        const collection = this.afDB.firestore.collection('advice')
        return await this.dBContextFirebaseService.getCollection(collection)
    }

    async getMemberAdvice(uid: string): Promise<Advice[]> {
        const collection = this.afDB.firestore.collection('advice')
            .where('memberUid', '==', uid).orderBy('dateUpdated', 'asc')
        return await this.dBContextFirebaseService.getCollection(collection)
    }

    async sendEmail(emailTo: string, message: string) {
        const getURL = 'https://us-central1-response24-functions.cloudfunctions.net/emergency/send-email'

        // TO DO THIS NEEDS TO BE A POST WITH A BODY AND NEEDS TO BE A CALLBACK
        this.http.post(getURL, {
            email: emailTo,
            message: message
        }).subscribe(
          data => {
            console.log('SUCCESS ', data);
          },
          error => {
            console.log('FAILED ', error);
          })
      }

}
