import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Globals } from '../globals';
import { User, Roles } from './user';
import { UserRoles } from 'app/enums/global.enums';

@Injectable()
export class AuthService {

  user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public g: Globals,
  ) {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null)
        }
      })
    )
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }

  emailLogin(email, password) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(credential => {
          resolve(credential.user)
        }
        ).catch(error => {
          reject(error)
        })
    })
  }

  emailRegister(user: User, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(user.email, password)
        .then(credential => {
          user.uid = credential.user.uid
          resolve(this.updateUserData(user))
        }
        ).catch(error => {
          reject(error)
        })
    })
  }

  resetPasswordWithMail(email) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then(function () {
        resolve('Reset Email has been sent to ' + email)
      }).catch(function (error) {
        reject(error)
        // An error happened.
      });
    })
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        //  this.updateUserData(credential.user)
      })
  }

  private updateUserData(user: User) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(user, { merge: true })
  }

  signOut(returnUrl) {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate([returnUrl]);
    });
  }

  ///// Role-based Authorization //////

  viewDashboard(user: User): boolean {
    const allowed = [
      UserRoles.viewDashboard.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageUsersAndRoles(user: User): boolean {
    const allowed = [
      UserRoles.manageRolesAndUsers.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageMembers(user: User): boolean {
    const allowed = [
      UserRoles.manageMembers.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  viewAuditTrail(user: User): boolean {
    const allowed = [
      UserRoles.viewAuditTrail.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  manageLanguageLibrary(user: User): boolean {
    const allowed = [
      UserRoles.manageLanguageLibrary.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageServiceAgreement(user: User): boolean {
    const allowed = [
      UserRoles.manageServiceAgreement.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageBillingChannels(user: User): boolean {
    const allowed = [
      UserRoles.manageBillingChannels.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  manageClientInformation(user: User): boolean {
    const allowed = [
      UserRoles.manageClientInformation.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageDollarEarnings(user: User): boolean {
    const allowed = [
      UserRoles.manageDollarEarnings.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  manageClientImages(user: User): boolean {
    const allowed = [
      UserRoles.manageClientImages.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageMedications(user: User): boolean {
    const allowed = [
      UserRoles.manageMedications.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  manageRewards(user: User): boolean {
    const allowed = [
      UserRoles.manageRewards.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  manageProgram(user: User): boolean {
    const allowed = [
      UserRoles.manageProgram.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageFitnessDevices(user: User): boolean {
    const allowed = [
      UserRoles.manageFitnessDevices.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageGlucoseMeters(user: User): boolean {
    const allowed = [
      UserRoles.manageGlucoseMeters.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageLoyaltyClubs(user: User): boolean {
    const allowed = [
      UserRoles.manageLoyaltyClubs.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageSupplyCompanies(user: User): boolean {
    const allowed = [
      UserRoles.manageSupplyCompanies.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageAdviceRequest(user: User): boolean {
    const allowed = [
      UserRoles.manageAdviceRequest.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageIssues(user: User): boolean {
    const allowed = [
      UserRoles.manageIssues.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  manageNews(user: User): boolean {
    const allowed = [
      UserRoles.manageNews.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  manageLearnTopics(user: User): boolean {
    const allowed = [
      UserRoles.manageLearnTopics.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  addLearnTopic(user: User): boolean {
    const allowed = [
      UserRoles.addLearnTopic.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  manageRewardFulfillment(user: User): boolean {
    const allowed = [
      UserRoles.manageRewardFulfillment.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  manageMemberCoaching(user: User): boolean {
    const allowed = [
      UserRoles.manageMemberCoaching.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  viewReports(user: User): boolean {
    const allowed = [
      UserRoles.viewReports.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  // User Auth
  user_manageMorbidities(user: User): boolean {
    const allowed = [
      UserRoles.user_manageMorbidities.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  user_manageMetrics(user: User): boolean {
    const allowed = [
      UserRoles.user_manageMetrics.variable
    ]
    return this.checkAuthorization(user, allowed)
  }


  user_manageCarePlan(user: User): boolean {
    const allowed = [
      UserRoles.user_manageCarePlan.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_managePrescription(user: User): boolean {
    const allowed = [
      UserRoles.user_managePrescription.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_manageActivity(user: User): boolean {
    const allowed = [
      UserRoles.user_manageActivity.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_manageNotes(user: User): boolean {
    const allowed = [
      UserRoles.user_manageNotes.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_manageAdviceRequest(user: User): boolean {
    const allowed = [
      UserRoles.user_manageAdviceRequest.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_managePatientChat(user: User): boolean {
    const allowed = [
      UserRoles.user_managePatientChat.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_manageEventLog(user: User): boolean {
    const allowed = [
      UserRoles.user_manageEventLogs.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_managePatientProfile(user: User): boolean {
    const allowed = [
      UserRoles.user_managePatientProfile.variable
    ]
    return this.checkAuthorization(user, allowed)
  }
  user_viewStatusReport(user: User): boolean {
    const allowed = [
      UserRoles.user_viewStatusReport.variable
    ]
    return this.checkAuthorization(user, allowed)
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user || !user.roles) { return false }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true
      }
    }
    return false
  }
}
