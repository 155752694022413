
import { Injectable } from '@angular/core';
import { ClientSetupService } from './client-setup.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';

import { MedicalCategory } from '../../../enums/global.enums'
// tslint:disable-next-line:max-line-length
import { Client, Medication, Reward, Billing, LicenseAgreement, DollarEarning, ProgramComponent, Image, RewardProvider } from '../../../interfaces/admin/client-setup.interface';
import { Advice } from 'app/interfaces/global.interface';

@Injectable()
export class ClientSetupFirebaseService implements ClientSetupService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }

    // Medication
    async setMedication(data: Medication): Promise<void> {
        const collection = this.afDB.collection('medication')
        this.dBContextFirebaseService.set(data, collection)
    }

    async getMedicationDetails(uid: string): Promise<Medication> {
        const docRef: firebase.firestore.DocumentData = this.afDB.firestore.collection('medication').doc(uid)
        return await this.dBContextFirebaseService.get(docRef)
    }

    async getAllTypeMedications(medicalCategory?: string): Promise<Medication[]> {

        const collection = this.afDB.firestore.collection('medication')
        if (medicalCategory) {
            const collectionWhere = collection.where('type', '==', MedicalCategory[medicalCategory])
            return await this.dBContextFirebaseService.getCollection(collectionWhere)
        } else {
            return await this.dBContextFirebaseService.getCollection(collection)
        }
    }

    // Client-Setup
    async getClientDetails(): Promise<Client> {
        const docRef: firebase.firestore.DocumentData = this.afDB.firestore.collection('clients').doc('default')
        return await this.dBContextFirebaseService.get(docRef)
    }
    async setClientDetails(data: Client): Promise<void> {
        const collection = this.afDB.collection('clients')
        this.dBContextFirebaseService.set(data, collection)
    }

    // Rewards
    async getRewards(providerUid: string): Promise<Reward[]> {
        const collection = this.afDB.firestore.collection('rewards')
            .where('providerUid', '==', providerUid)
        return await this.dBContextFirebaseService.getCollection(collection)
    }
    async setReward(data: Reward): Promise<any> {
        const collection = this.afDB.collection('rewards')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Rewards
    async getRewardProviders(uid?: string): Promise<RewardProvider[]> {
        const collection = this.afDB.firestore.collection('rewardProviders')

        if (uid) {
            const collectionWhere = collection.where('uid', '==', uid)
            return await this.dBContextFirebaseService.getCollection(collectionWhere)
        } else {
            return await this.dBContextFirebaseService.getCollection(collection)
        }
    }

    async setRewardProvider(data: RewardProvider): Promise<RewardProvider> {
        const collection = this.afDB.collection('rewardProviders')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Billing
    async getBilling(): Promise<Billing[]> {
        const collection = this.afDB.firestore.collection('billings')
        return await this.dBContextFirebaseService.getCollection(collection)
    }
    async setBilling(data: Billing): Promise<void> {
        const collection = this.afDB.collection('billings')
        this.dBContextFirebaseService.set(data, collection)
    }

    // License-agreement
    async getLicenseAgreements(): Promise<LicenseAgreement[]> {
        const collection = this.afDB.firestore.collection('licenseAgreements')
        return await this.dBContextFirebaseService.getCollection(collection)
    }
    async setLicenseAgreement(data: LicenseAgreement): Promise<LicenseAgreement> {
        const collection = this.afDB.collection('licenseAgreements')
        return this.dBContextFirebaseService.set(data, collection)
    }

    // Dollar Earning
    async getDollarEarnings(): Promise<DollarEarning[]> {
        const collection = this.afDB.firestore.collection('dollarEarnings')
        return await this.dBContextFirebaseService.getCollection(collection)
    }
    async setDollarEarning(data: DollarEarning): Promise<void> {
        const collection = this.afDB.collection('dollarEarnings')
        this.dBContextFirebaseService.set(data, collection)
    }

    // Program
    async getProgramComponents(): Promise<ProgramComponent[]> {
        const collection = this.afDB.firestore.collection('programComponents')
        return await this.dBContextFirebaseService.getCollection(collection)
    }
    async setProgramComponents(data: ProgramComponent): Promise<void> {
        const collection = this.afDB.collection('programComponents')
        this.dBContextFirebaseService.set(data, collection)
    }

    // Logo And wallpaper
    async setImageUrl(data: Image): Promise<Image> {
        const collection = this.afDB.collection('images')
        return this.dBContextFirebaseService.set(data, collection)
    }
    async geImageUrls(): Promise<Image[]> {
        const collection = this.afDB.firestore.collection('images')
        .where('isActive' , '==', true)
        return await this.dBContextFirebaseService.getCollection(collection)
    }
}
