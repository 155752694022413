
import { Injectable, forwardRef } from '@angular/core';
import { GeneralService } from './general.service';
import { Data } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';

// Interfaces
import { LanguageLibrary, Language } from 'app/interfaces/admin/language.interface';

// services
import { DBContextFirebaseService } from '../../db-context/db-context.firebase.service';

@Injectable()
export class GeneralFirebaseService implements GeneralService {

    constructor(
        private afDB: AngularFirestore,
        private dBContextFirebaseService: DBContextFirebaseService

    ) { }

    async getLanguageLibrary(language: string): Promise<LanguageLibrary[]> {
        const collection = this.afDB.firestore
            .collection('languageLibrary')
            .orderBy('page', 'desc')
            .where('language', '==', language)
        const returnList = await this.dBContextFirebaseService.getCollection(collection)
        return returnList;
    }

    async getLanguageList(): Promise<Language[]> {
        const collection = this.afDB.firestore.collection('settings').doc('default').collection('languages')
        const returnList = await this.dBContextFirebaseService.getCollection(collection)

        return returnList;
    }

    async setLanguageLibrary(newLanguage: LanguageLibrary, languageList?: Language[]): Promise<LanguageLibrary> {
        let setOthers = false;
        if (newLanguage.uid === '' || newLanguage.uid === null || newLanguage.uid === undefined) {
            setOthers = true;
        }

        const collection = this.afDB.collection('languageLibrary')
        const objectUid = await this.dBContextFirebaseService.set(newLanguage, collection)
        // Add new phrase for the rest of the languages
        if (setOthers && languageList) {

            languageList = languageList.filter(x => x.name !== 'english')
            languageList.forEach(language => {
                newLanguage.language = language.name
                newLanguage.uid = ''
                newLanguage.translation = ''
                this.dBContextFirebaseService.set(newLanguage, collection)
            });
        }
        newLanguage.uid = objectUid
        return newLanguage
    }

    async setLanguage(newLanguage: Language): Promise<LanguageLibrary[]> {

        const collection = this.afDB.collection('settings').doc('default').collection('languages')

        this.dBContextFirebaseService.set(newLanguage, collection)

        // Create a new list of Language.
        return this.getLanguageLibrary('english')
            .then(result => {

                result.forEach(element => {
                    element.translation = ''
                    element.language = newLanguage.name
                    element.uid = ''
                    // Note dateUpdated is still the old date, fix later
                    this.setLanguageLibrary(element)
                });
                return result;
            })
    }

    async removeLanguage(language: Language): Promise<boolean> {


        // THIS MUST BE TRANSFERRED TO A FUNCTION
        await this.afDB.firestore.collection('settings').doc('default').collection('languages')
            .doc(language.uid).delete()

        await this.afDB.firestore.collection('languageLibrary')
            .where('language', '==', language.name)
            .get().then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                  await doc.ref.delete();
                    console.log('deleted : ' + doc.data().uid)
                });
            });


        return true;
    }
}
