import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router, ResolveEnd } from '@angular/router';
import { OldShitGlobalsToRemove } from '../../app/oldshitglobalstoremove';
import { Globals } from '../../app/globals';

@Injectable()

// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------
// ----- THIS THOULD BE REMOVED !!! OLD SHIT!!!! -----------

export class PatientCarePlan {

    balance = 0
    constructor(
        public router: Router,
        public afDB: AngularFirestore,
        public g: OldShitGlobalsToRemove,
        public gb: Globals
    ) { }


    UpdatateCarePlan(carePlanType: string) {

        let carePlanStupidFUCKINGVariableCIRCUS = '';

        if (carePlanType === 'Lipogram Test') {
            carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.cholesterolLipogramTest

        } else
            if (carePlanType === 'Diabetes Educator Consultation') {
                carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.diabetesEducatorConsultation

            } else
                if (carePlanType === 'Dietician Consultation') {
                    carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.dieticianConsultation

                } else
                    if (carePlanType === 'Doctor Consultation') {
                        carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.doctorConsultation

                    } else
                        if (carePlanType === 'Eye Risk Screening') {
                            carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.eyeScreening

                        } else
                            if (carePlanType === 'Foot Risk Screening') {
                                carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.footScreening

                            } else
                                if (carePlanType === 'HbA1c Test') {
                                    carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.HbA1cTest

                                } else
                                    if (carePlanType === 'MAU Test') {
                                        carePlanStupidFUCKINGVariableCIRCUS = this.g.manage.MAUACRTest
                                    }

        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid

            // Check if user is inside careplan boundries
            this.GetCurrentPatientCarePlanAmounts(carePlanStupidFUCKINGVariableCIRCUS)
                .then((careplanRes) => {

                    let used = careplanRes['used']
                    if (used === undefined || used === '' || used === null) {
                        used = 0
                    }

                    // Update Used Amount
                    used++;
                    const updatedSet = {
                        used: used,
                    }
                    this.afDB
                        .collection('users')
                        .doc(patientId)
                        .collection(this.g.otherOptions['manage'])
                        .doc(carePlanStupidFUCKINGVariableCIRCUS)
                        .set(updatedSet, { merge: true })
                        .then(data => {
                            resolve('Careplan Metric Used Updated')
                        })
                        .catch(function (error) {
                            this.message = 'Error : ' + error
                            console.error('Error Adding Note: ', error);
                            this.showNotification(this.message, 'top', 'center', 'notifications')
                        });
                }).catch((error) => {
                    reject('No target has been added for the selected Event')
                }
                )
        })
    }

    GetCurrentPatientCarePlanAmounts(carePlan: string) {
        console.log('GetCurrentPatientCarePlanAmounts')

        return new Promise((resolve, reject) => {
            const patientId = this.gb.getSelectedMember().uid

            this.afDB.firestore
                .collection('users')
                .doc(patientId)
                .collection('manage')
                .doc(carePlan)
                .get()
                .then(doc => {
                    resolve(doc.data())
                }).catch((error) => reject(error))
        })
    }
}


